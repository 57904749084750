<template>
    <b-container id="obrigado" class="height-full" fluid>
        <b-row style="padding-top: 50px">
            <b-col class="text-center">
                <b-img-lazy style="height: 76px"
                            alt="ZZVendas" fluid
                            :src=" require( '../../assets/logos/zzvendas-banner-white.png') "
                ></b-img-lazy>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="text-center">
                <h1 style="padding-top: 50px">
                    OBRIGADO PELO SEU CADASTRO,
                    {{ Nome }}
                </h1>
                <p>
                    Enviamos um e-mail para você, <br/>
                    Acesse ele e faça a confirmação!
                </p>
            </b-col>
        </b-row>
        <b-row class="rodape">
            <b-col class="text-center">

                <!-- Logo -->
                <div>
                    <b-img-lazy style="height: 50px"
                                alt="ZZVendas" fluid
                                :src=" require( '../../assets/logos/arezzoco-branco.png') "
                    ></b-img-lazy>
                </div>

                <!-- Social Media -->
                <div>
                    <b-img-lazy style="height: 20px"
                                alt="Messenger" fluid
                                :src=" require( '../../assets/logos/messenger-branco.png') "
                    ></b-img-lazy>
                    <b-img-lazy style="height: 20px; padding-left: 10px; padding-right: 10px"
                                alt="Instagram" fluid
                                :src=" require( '../../assets/logos/instagram-branco.png') "
                    ></b-img-lazy>
                    <b-img-lazy style="height: 20px"
                                alt="Youtube" fluid
                                :src=" require( '../../assets/logos/youtube-branco.png') "
                    ></b-img-lazy>
                </div>

                <!-- Rodapé -->
                <div style="padding-top: 30px">
                    <b-img-lazy style="height: 20px"
                                alt="ZZVendas" fluid
                                :src=" require( '../../assets/logos/anacapri-branco.png') "
                    ></b-img-lazy>
                    <b-img-lazy style="height: 15px"
                                alt="ZZVendas" fluid
                                :src=" require( '../../assets/logos/arezzo-branco.png') "
                    ></b-img-lazy>
                    <p>Todos os direitos reservados Arezzo&Co</p>
                </div>

            </b-col>
        </b-row>
    </b-container>
</template>
<style lang="scss">
#obrigado
{
    display: grid;

    *
    {
        font-family: "Montserrat", Arial, Helvetica, sans-serif !important;
        color: white;
    }

    h1
    {
        font-size: 33px;
    }

    p
    {
        font-size: 27px;
    }

    .rodape p
    {
        font-size: 10px;
    }
}
</style>
<script>

import {Utils} from "@/components/utils";

export default {
    name: 'obrigado',
    mixins: [Utils],
    props: ['Nome'],
    mounted() {
        document.title = 'Obrigado pelo cadastro';
        document.getElementsByTagName('body')[0].style.backgroundColor = '#1EBCD5';
    },
}
</script>
